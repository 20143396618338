@import '../../node_modules/bootstrap/scss/_functions';
@import 'theme/functions';

@import '../../node_modules/bootstrap/scss/mixins';
@import 'theme/mixins';

@import 'user-variables';
@import 'theme/variables';
@import '../../node_modules/bootstrap/scss/variables';

//
//
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.card .list-group-flush:first-child {
  margin: 0 calc(var(--phoenix-card-spacer-x)  * -1);
}
.card .list-group-flush .list-group-item {
  padding-left: var(--phoenix-card-spacer-x);
  padding-right: var(--phoenix-card-spacer-x);
}
.navbar-brand {
  height : 100%;
}
.navbar-brand img {
  max-height: 100%;
  width: auto;
  padding: 0.25rem 0;
}

.filter .d-flex .badge {
  align-self: center;
}
.bg-light .list-group-item:not(.active) {
  background-color: initial;
}
.choices {
  overflow: visible;
}
.choices, .choices .choices__inner, .choices .choices__list--dropdown .choices__item--selectable {
  font-size: inherit !important;
}
.choices[data-type*=select-one] .choices__input {
  padding: .25rem .5rem;
}
.form-label {
  padding-left:.5rem;
}

.table.text-white tbody td {
  border-color: $gray-600;
}
.table>tfoot>tr>*:first-child,
.table>thead>tr>*:first-child {
    padding-left: 0;
}
.table>tfoot>tr>*:last-child,
.table>thead>tr>*:last-child {
    padding-right: 0;
}